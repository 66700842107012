import * as moment from 'moment';

export const leaveModes = [
    { label: 'Select Mode', value: '' },
    { label: 'Full Day', value: 'FULL' },
    { label: 'HALF DAY | AM', value: 'AM' },
    { label: 'HALF DAY | PM', value: 'PM' },
];

export const canHalfDayLeave = (mode, dateStart, dateEnd) => {
    const momentStart = moment(dateStart);
    const momentEnd = moment(dateEnd);
    const daysDifference = Math.abs(momentStart.diff(momentEnd, 'day'));
    if (mode === 'FULL') return true;
    return ['AM', 'PM'].includes(mode) && daysDifference < 1;
};
