import { axiosInstance, getAuthorizationHeaders } from '@/services/base';

const roleUrlString = localStorage.getItem('mainRole') === 'admin' ? `admin/requests` : `requests`;

export function postLeaves(data) {
    const urlString = `users/leaves/submit`;
    return axiosInstance.post(urlString, data, getAuthorizationHeaders());
}
export function getLeavesInfo(){
    const urlString = `users/leaves/get-leaves-info`;
    return axiosInstance.get(urlString, getAuthorizationHeaders())
}

export function getLeavesInfoViaAdmin(user){
    const urlString = `admin/leave-credit/get-available-employee-credits`;
    return axiosInstance.get(urlString, { params: { user }, ...getAuthorizationHeaders() });
}

export function getPaginatedRequestRecordsByUser(userId, filter, page = 1, limit = 1000) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    const urlString = `/paginated/${userId}/records`;
    return axiosInstance.get(roleUrlString + urlString, {
        params: { filter: filter, limit: limit, page: page, sort: { createdAt: -1 } },
        headers,
    });
}
/*Admin Api Endpoints*/
export function requestProcess(data) {
    const urlString = `/process`;
    return axiosInstance.post(roleUrlString + urlString, data, getAuthorizationHeaders());
}

