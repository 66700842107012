<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="Leaves"
            width="600"
            no-footer
            :closable="closable"
            class="viewTimeDispute"
            :before-close="handleReset"
        >
            <Form
                ref="form"
                :model="form"
                :loading="isLoading"
                label-position="left"
                class="create-time-dispute-wrapper"
            >
                <Row v-if="isAdmin">
                    <Col span="">
                        <FormItem label="Employee">
                            <UserList :user="form.user._id" list-disabled />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="">
                        <FormItem label="Leave Mode">
                            <Select
                                v-model="form.requestedData.mode"
                                :disabled="readOnly"
                                style="width: 200px"
                                :value="form.mode"
                                default-label="FULL DAY"
                            >
                                <Option v-for="item in leaveModes()" :key="item.value" :value="item.value">{{
                                    item.label
                                }}</Option>
                            </Select>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="12">
                        <FormItem label="Start Date" prop="startDate">
                            <DatePicker
                                v-model="form.requestedStart"
                                type="date"
                                format="dd-MMM-yyyy"
                                :disabled="readOnly"
                                @on-change="onDateStartChange"
                            ></DatePicker>
                        </FormItem>
                    </Col>
                    <Col>
                        <FormItem label=" Date" prop="endDate">
                            <DatePicker
                                v-model="form.requestedEnd"
                                format="dd-MMM-yyyy"
                                transfer
                                :disabled="readOnly"
                                :loading="isLoading"
                                @on-change="onDateEndChange"
                            />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span="12">
                        <FormItem label="Leave Type" prop="requestedStartTime">
                            <v-radio-group v-model="form.requestedData.type" :disabled="readOnly">
                                <v-radio value="vl">
                                    <template slot="label">
                                        &nbsp;Vacation Leave&nbsp;<b>({{ leaves.vl }})</b>
                                    </template>
                                </v-radio>
                                <v-radio value="sl">
                                    <template slot="label">
                                        &nbsp;Emergency/Sick Leave&nbsp;<b>({{ leaves.sl }})</b>
                                    </template></v-radio
                                >
                                <v-radio label="Appreciation Leave" value="al"
                                    ><template slot="label">
                                        &nbsp;Appreciation Leave&nbsp;<b>({{ leaves.al }})</b>
                                    </template></v-radio
                                >
                            </v-radio-group>
                        </FormItem>
                    </Col>

                    <Col span="">
                        <FormItem label="Status">
                            <div :class="getStatusClass(form.status)">{{ form.status }}</div>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="Reason for request (optional)">
                            <Input
                                v-model="form.reason"
                                type="textarea"
                                :disabled="readOnly"
                                :autosize="{ minRows: 2, maxRows: 5 }"
                                placeholder="Enter something..."
                                style="width: 400px"
                            />
                        </FormItem>
                    </Col>
                </Row>
                <div>
                    <Button class="pt-btn-2" style="margin-right: 10px" @click="handleCancel"> CANCEL </Button>
                    <Button
                        v-if="isAdmin && form._id && form.status === 'pending'"
                        class="pt-btn-4"
                        :disabled="approveButtonReadOnly"
                        :loading="isLoading"
                        @click="handleRequest('approved')"
                    >
                        APPROVE
                    </Button>

                    <Button
                        v-if="isAdmin && form._id && form.status === 'pending'"
                        :disabled="rejectButtonReadOnly"
                        :loading="isLoading"
                        class="pt-btn-2 danger"
                        style="margin-right: 10px; margin-left: 10px"
                        @click="handleRequest('rejected')"
                    >
                        REJECT
                    </Button>
                </div>
            </Form>
        </Modal>
    </div>
</template>
<script>
import moment from 'moment';
import { getRequestRecordByUserAndAttendanceId, requestLeaveProcess } from '@/services/requests.services';
import { timeConversion } from '../../../../../../utility/helper';
import { getLeavesInfo, getLeavesInfoViaAdmin } from '@/services/leave.services';
import { leaveModes } from '@/components/Requests/Dev/Leave/LeaveRequest/utils/helper.ts';
import UserList from '@/components/Users/UserList.vue';
export default {
    components: { UserList },
    data() {
        return {
            readOnly: false,
            form: {
                createdAt: null,
                reason: '',
                requestedData: null,
            },
            isAdmin: false,
            leaves: {},
            rejectButtonReadOnly: false,
            approveButtonReadOnly: false,
            isLoading: false,
            details: null,
            showModal: false,
            closable: false,
            attendanceId: null,
            userId: null,
            mainRole: localStorage.getItem('mainRole') === 'admin',
            isPending: false,
        };
    },
    computed: {},
    methods: {
        getStatusClass(status) {
            const data = {
                pending: 'warning',
                rejected: 'danger',
                approved: 'success',
            };
            return `statusLabel ${data[status]}`;
        },
        leaveModes() {
            return leaveModes;
        },
        async getSelfLeaveInfo() {
            const { data } = await getLeavesInfo();
            this.leaves.sl = data['remaining-sick-leave'];
            this.leaves.al = data['remaining-appreciation-leave'];
            this.leaves.vl = data['remaining-vacation-leave'];
        },
        getDateOnly(datetime) {
            return moment(datetime).format('yyyy-MM-DD');
        },
        getDaysDifference(from, to) {
            const days = Math.abs(moment(from).startOf('day').diff(moment(to).endOf('day'), 'days'));
            return days + 1;
        },
        onDateStartChange(date) {
            this.form.requestedStart = new Date(date);
            if (!date) {
                this.form.requestedStart = '';
            }
            if (!this.form.requestedStart || this.form.requestedStart > this.form.requestedEnd) {
                this.form.endDate = new Date(date);
            }
        },
        onDateEndChange(date) {
            if (!date) {
                this.form.requestedEnd = '';
            } else {
                if (!this.form.requestedStart || this.form.requestedStart > this.form.requestedEnd) {
                    this.form.requestedStart = new Date(date);
                }
                this.form.requestedEnd = new Date(date);
            }
        },
        async handleGetRequestRecordByAttendanceId(data) {
            return await getRequestRecordByUserAndAttendanceId(this.attendanceId, this.userId, {
                requestedStart: timeConversion(moment.utc(data.createdAt).format('DD-MMM-YYYY'), '00:00'),
            });
        },
        async openModal(data) {
            console.log('data2: ', data);
            this.userId = data.user._id;
            this.form = {
                ...data,
                requestedStart: new Date(data.requestedStart),
                requestedEnd: new Date(data.requestedEnd),
            };
            this.isPending = data.status === 'pending';
            this.showModal = true;
        },

        handleStatusLabel(data) {
            const status = data.status;
            let label = '',
                className = 'statusLabel';
            const upperCaseStats = status.toString().toUpperCase();
            switch (status.toString().trim()) {
                case 'pending':
                    label = upperCaseStats;
                    className = `${className} warning`;
                    break;
                case 'rejected':
                    label = upperCaseStats;
                    className = `${className} danger`;
                    break;
                case 'approved':
                    label = upperCaseStats;
                    className = `${className} success`;
                    break;

                default:
                    break;
            }
            return { label, className };
        },

        handleCancel() {
            this.showModal = false;
        },

        handleConfirm() {
            this.showModal = false;
        },
        handleReset() {
            this.showModal = false;
        },

        async openModalForReadOnly(data) {
            await this.getSelfLeaveInfo();
            this.userId = data.user._id;
            this.form = {
                ...data,
                requestedStart: new Date(data.requestedStart),
                requestedEnd: new Date(data.requestedEnd),
            };
            this.isPending = data.status === 'pending';
            this.showModal = true;
            this.isAdmin = false;
            this.readOnly = true;
        },

        async openModalForAdmin(data) {
            this.userId = data.user._id;
            await this.getEmployeeCreditByAdmin();
            console.log('openModalForAdmin: ', data);
            this.form = {
                ...data,
                requestedStart: new Date(data.requestedStart),
                requestedEnd: new Date(data.requestedEnd),
            };
            this.isPending = data.status === 'pending';
            this.showModal = true;
            this.readOnly = true;
            this.approveButtonReadOnly = false;
            this.rejectButtonReadOnly = false;
            this.isAdmin = true;
        },

        async getEmployeeCreditByAdmin() {
            const { data: leaves } = await getLeavesInfoViaAdmin(this.userId);
            this.leaves.sl = leaves['remaining-sick-leave'];
            this.leaves.al = leaves['remaining-appreciation-leave'];
            this.leaves.vl = leaves['remaining-vacation-leave'];
        },

        handleRequest(status) {
            this.isLoading = true;
            const data = {
                id: this.form._id,
                status: status,
            };
            requestLeaveProcess(data)
                .then((res) => {
                    if (res && res.data.success) {
                        this.$Message.success(`Request has been ${status}`);
                        this.isLoading = false;
                        this.$emit('create');
                        this.showModal = false;
                    }
                })
                .catch((err) => {
                    if (err.data.statusCode === 400 && err.data.message === 'request not found') {
                        this.$Message.error('Request not found');
                    } else if (err.data.statusCode === 403 && err.data.message === 'request already processed') {
                        this.$Message.error(
                            'You`ve already processed this request time dispute. Please do check it again.'
                        );
                    } else {
                        this.$Message.error('Failed to process request time dispute');
                    }
                    this.isLoading = false;
                });
        },
    },
};
</script>
<style>
.viewTimeDispute .ivu-modal-body {
    padding: 16px;
}
.viewTimeDispute .ivu-modal-footer {
    display: none;
}
.viewTimeDispute .ivu-spin-main {
    justify-content: center;
    display: flex;
}
.viewTimeDispute button {
    font-weight: bold;
    margin-left: 10px;
}
.statusLabel {
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    max-width: 250px;
    padding: 5px;
    text-align: center;
}
.success {
    background: #49e97e;
    font-weight: bold;
}
.warning {
    background: #ffc107;
    font-weight: bold;
}
.danger {
    background: #e9495d;
    font-weight: bold;
}
</style>
